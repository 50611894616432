import store from '@/store/index';
import LocalStorageManager from '@/storages/LocalStorageManager';

export default class WalletChildModel {
  constructor(){
    this.wallet_amount = null;
    this.money_amount = null;
    this.point_amount = null;
    this.user = {
      account_id : "",
      name : "",
      nick_name : "",
      profile_image_url : null,
      role : "",
      status : "",
      user_uid : null,
    };
    this.has_transaction = null;
    this.wallet_transaction_list = [];

    this.paymentCategory = {}
  }
  setData(obj){
    const { wallet, user, transaction, payment_category } = obj;
    this.wallet_amount = wallet.amount;
    this.money_amount = wallet.money_amount;
    this.point_amount = wallet.point_amount;

    this.user = user;
    LocalStorageManager.shared.setUserData(user);
    store.dispatch('commonUserData/fetchUserData', user);
    
    this.has_transaction = transaction.has_transaction;
    this.wallet_transaction_list = transaction.transaction_list;

    this.paymentCategory = payment_category;
  }
}