import WalletChildModel from '@/views/wallet/model/WalletChildModel';
import { GET_REQUEST } from '@/services/http';
import apiPath from '@/services/apiPath';
import store from '@/store/index';
import router from '@/router';

export default class WalletMainChildViewModel {
  constructor() {
    this.model = new WalletChildModel();
  }
  init(){
    this.getWalletMain();
  }

  onClickDetail(transaction_uid){
    let newQuery = JSON.parse(JSON.stringify(router.app._route.query));
    newQuery.userType = this.userType;
    newQuery.closeRouteName = 'WalletMainChild';
    router.push({name:'TransDetail', params:{ id : transaction_uid }, query: newQuery});
  }
  
  getWalletMain(){
    const path = `${apiPath.WALLET_MAIN_CHILD}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data.body;
      this.model.setData(resultData)
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
}