<template>
  <div
    :class="['badge_number', `badge_number_${size}`, { 'badge_oval' : isOverHundred }]"
    :style="cssProps">{{ value }}</div>
</template>

<script>
export default {
  name:'BadgeNum',
  props:{
    value: [Number, String],
    size: {
      type: Number,
      default: 20 // 20 || 24
    }
  },
  computed:{
    isOverHundred(){
      return Number(this.value) > 99
    },
    cssProps(){
      let ovalPadding = 6;
      switch(this.size){
        case 20 : { ovalPadding = 6; break; }
        case 24 : { ovalPadding = 8; break; }
      }
      return{
        '--size' : `${this.size}px`,
        '--ovalPadding' : `0 ${ovalPadding}px`,
        'border-radius': `${this.size/2}px`
      }
    }
  },
}
</script>
<style scoped>
.badge_number{display:inline-block;width:var(--size);height:var(--size);font-weight:600;font-size:12px;line-height:var(--size);text-align:center;background-color:#F98E5A;color:#111;vertical-align:top;box-sizing:border-box}

.badge_number.badge_oval{width:auto;padding:var(--ovalPadding) }
</style>