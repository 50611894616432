<template>
  <PageWithLayout
    bgColor="#F3F1F1"
    :isBackBtn="true"
    :isBackWebEnd="true"
    :isTitle="true"
    :title="$poData.child_wallet_main_title.format(viewModel.model.user.name)"
    :isRightTextMenu="true"
    :rightTextMenu="$poData.child_wallet_main_setting"
    :isBodyFull="true"
    @onClickRightMenu="pageMove('cWalletSet')">
    <div class="area_top">
      <WalletHead
        :title="$poData.child_wallet_main_balance"
        :amount="viewModel.model.wallet_amount"
        :money_amount="viewModel.model.money_amount"
        :point_amount="viewModel.model.point_amount">
        <template v-slot:inFold>
          <Button
            v-if="has_return_btn"
            class="btn_return"
            btnStyle="primary"
            :text="$poData.child_wallet_main_send_money_btn"
            @onClickBtn="pageMove('sendPocketMoneyReturn')" />
        </template>
      </WalletHead>
      <CategoryStatistic
        :paymentCategory="viewModel.model.paymentCategory"
        routeName="ChildTransList"/>
    </div>
    <ButtonRouter
      class="btn_router_transList"
      :text="$poData.child_wallet_main_heading_2"
      :isArrowRight="viewModel.model.has_transaction"
      :routeName="viewModel.model.has_transaction ? 'ChildTransList' : ''"
      :routeQuery="{ has_return_btn : has_return_btn }">
      <template v-if="viewModel.model.has_transaction" v-slot:btn_right>
        <span class="txt_router">{{ $poData.child_wallet_main_show_all }}</span>
      </template>
    </ButtonRouter>
    <template v-if="viewModel.model.has_transaction">
      <ListItem
        v-for="(itemData, index) in viewModel.model.wallet_transaction_list"
        :key="`transItem${index}`"
        :padding="17"
        :thumbNail="{
          size: 40,
          url : itemData.profile_image_url
        }"
        :title="itemData.title"
        :leftSubText="itemData.sub_title"
        :leftBadge="{
          value : itemData.label_display_name,
          type : 'sub1'
        }"
        :rightSubText="itemData.amount | convertNumberToComma(false,'0','원')"
        :isRightSubTextColor="itemData.amount >= 0 ? 'red' : ''"
        @onClickItem="viewModel.onClickDetail(itemData.transaction_uid)">
      </ListItem>
    </template>
    <template v-else>
      <p class="desc_empty">{{ $poData.child_wallet_main_no_trans }}</p>
    </template>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@/components/layout/PageWithLayout';
import WalletHead from '@/components/common/walletHead/WalletHead';
import Button from '@/components/common/button/Button';
import CategoryStatistic from '@/components/common/categoryStatistic/CategoryStatistic';
import ButtonRouter from '@/components/common/button/ButtonRouter';
import BadgeNum from '@/components/common/badge/BadgeNum';
import ListItem from '@/components/common/list/ListItem';
// mixins
import InterFaceMixin from '@/mixins/InterFaceMixin';
import BackKeyEventMixin from '@/mixins/BackKeyEventMixin';
// viewModel
import WalletMainChildViewModel from '@/views/wallet/viewModel/WalletMainChildViewModel'

export default {
  name: 'WalletMain',
  mixins:[ InterFaceMixin, BackKeyEventMixin ],
  components:{
    PageWithLayout,
    WalletHead,
    Button,
    CategoryStatistic,
    ButtonRouter,
    BadgeNum,
    ListItem,
  },
  beforeMount(){
    this.has_return_btn = this.$route.query.has_return_btn && String(this.$route.query.has_return_btn) === 'true';
    this.viewModel.userType = 'CHILD';
    this.viewModel.init();

    // 상세다녀와서 closeRouteName 제거
    let currentQuery = JSON.parse(JSON.stringify(this.$route.query));
    currentQuery.closeRouteName = '';
    let newQuery = Object.fromEntries(Object.entries(Object.assign(currentQuery)).filter(([_, v]) => v != '' && v != undefined  && v != 'undefined' ));
    this.$router.replace({'query': newQuery}).catch(()=>{});
  },
  data(){
    return{
      viewModel: new WalletMainChildViewModel(),
      has_return_btn: false
    }
  },
  methods:{
    onClickBackKey(){
      if(this.viewModel.isMoneyInfoPopup){
        this.viewModel.onClickMoneyInfoClose();
      }
      else if(this.viewModel.isPointInfoPopup){
        this.viewModel.onClickPointInfoClose();
      }
      else{
        this.webEnd();
      }
    }
  }
}
</script>

<style scoped>
.area_top{padding:24px 20px 24px;background-color:#F3F1F1}
.btn_return{margin-top:16px}
.category_statistic{margin-top:24px}

.btn_router_transList{background-color:#fff}
</style>